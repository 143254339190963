import { makeVar } from '@apollo/client'

export const isBoardsManagerOpenVar = makeVar(false)

export const toggleIsBoardsManagerOpen = () => {
  isBoardsManagerOpenVar(!isBoardsManagerOpenVar())
}

export const activeBoardIdVar = makeVar(null)

export const useActiveBoardId = () => {
  const setActiveBoardId = (boardId) => {
    activeGoalIdVar(null)
    activeBoardIdVar(boardId)
  }

  return [
    activeBoardIdVar,
    setActiveBoardId
  ]
}

export const activeGoalIdVar = makeVar(null)

export const activeModalVar = makeVar({ id: null, props: null })

export const activeUserVar = makeVar(null)
