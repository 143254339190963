import { withAuthenticationRequired } from '@auth0/auth0-react'
import React from 'react'
import { Route } from 'react-router-dom'

import { LoadingIndicator } from './loading-indicator'

export const ProtectedRoute = ({ component, ...props }) => {
  return (
    <Route
      component={
        withAuthenticationRequired(
          component,
          {
            returnTo: props.path,
            onRedirecting: () => <LoadingIndicator size='fullscreen'/>
          }
        )
      }
      {...props}
    />
  )
}