import { Flex, Spinner, Text } from '@chakra-ui/react'
import React from 'react'

export const LoadingIndicator = ({ label = 'Loading...', size, ...props }) => {
  const sizeProps = size === 'fullscreen' ? {
    width: '100vw',
    height: '100vh'
  } : {}

  return (
    <Flex
      flex={1}
      direction='column'
      align='center'
      justify='center'
      {...sizeProps}
      {...props}
    >
      <Spinner/>
      <Text mt={2}>{label}</Text>
    </Flex>
  )
}