import { defineAbility } from '@casl/ability'
import { usePrevious } from '@chakra-ui/react'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'

export const AbilityContext = createContext(undefined)

export const AbilityProvider = ({ children, ...props }) => {
  const initialAbility = defineAbility(() => null)
  const [ ability, setAbility ] = useState(initialAbility)

  return (
    <AbilityContext.Provider value={[ ability, setAbility ]} {...props}>
      {children}
    </AbilityContext.Provider>
  )
}

export const useAbility = () => {
  const [ ability ] = useContext(AbilityContext)
  const can = useCallback((...args) => ability.can(...args), [ ability ])
  const cannot = useCallback((...args) => ability.cannot(...args), [ ability ])
  return { can, cannot }
}

export const useAbilityDefinition = (definer) => {
  const previousDefiner = usePrevious(definer)
  const [ , setAbility ] = useContext(AbilityContext)

  useEffect(() => {
    setAbility(
      defineAbility(definer, {
        detectSubjectType: object => {
          return object ? object.__typename : 'all'
        }
      })
    )
  }, [ definer, setAbility ])

  const isDefining = !Object.is(definer, previousDefiner)
  return { isDefining }
}