import { theme } from '@chakra-ui/react'
import 'fontsource-lato/latin-300-normal.css'
import 'fontsource-lato/latin-400.css'
import 'fontsource-lato/latin-700-normal.css'

const entityPalette = [
  'accent1',
  'accent2',
  'accent3',
  'accent4',
  'accent5',
  'accent6',
  'accent7',
  'accent8',
  'accent9',
  'accent10',
  'accent11',
  'accent12'
]

export const defaultTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    null: {
      100: 'transparent'
    },
    goals: entityPalette,
    resources: entityPalette,
    primary: {
      50: '#636363',
      100: '#575757',
      500: '#3d3d3d',
      600: '#303030',
      700: '#242424'
    },
    secondary: {
      500: '#eeeeee',
      600: '#e1e1e1',
      700: '#d5d5d5'
    },
    tertiary: {
      500: '#cfd7d9',
      600: '#c1cbce',
      700: '#b3bfc2'
    },
    accent: '',
    muted: '#cfd7d9',
    text: {
      primary: '#333333',
      secondary: '#ffffff',
      muted: '#678'
    },
    background: {
      primary: '#ffffff',
      secondary: '#eef5f7',
      tertiary: '#eee',
      muted: '#d4e3e6'
    },
    border: {
      primary: '#bfc2c4',
      secondary: '#333333'
    },
    avatar: {
      primary: '#f7f9f9',
      secondary: '#333333'
    },
    cell: {
      primary: '#eeeeee',
      secondary: '#cfd7d9'
    },
    segmentedProgress: {
      color: '#333333',
      track: '#eeeeee',
      overflow: '#0009'
    },
    accent1: {
      primary: '#cc4b42',
      secondary: '#ffffff'
    },
    accent2: {
      primary: '#d53c63',
      secondary: '#ffffff'
    },
    accent3: {
      primary: '#772f84',
      secondary: '#ffffff'
    },
    accent4: {
      primary: '#643da7',
      secondary: '#ffffff'
    },
    accent5: {
      primary: '#9844a6',
      secondary: '#ffffff'
    },
    accent6: {
      primary: '#0e75ce',
      secondary: '#ffffff'
    },
    accent7: {
      primary: '#118291',
      secondary: '#ffffff'
    },
    accent8: {
      primary: '#317f77',
      secondary: '#ffffff'
    },
    accent9: {
      primary: '#7b7563',
      secondary: '#ffffff'
    },
    accent10: {
      primary: '#489f4b',
      secondary: '#ffffff'
    },
    accent11: {
      primary: '#cfdd45',
      secondary: '#000000'
    },
    accent12: {
      primary: '#f29f29',
      secondary: '#000000'
    }
  },
  radii: {
    ...theme.radii,
    'sm': '0.1875rem',
    'xs': '0.0625rem'
  },
  fonts: {
    ...theme.fonts,
    heading: 'Lato, ' + theme.fonts.heading,
    body: 'Lato, ' + theme.fonts.body
  },
  shadows: {
    ...theme.shadows,
    header: '0px 2px 14px rgba(0, 0, 0, 0.0821678)',
    lightOutline: '0 0 0 3px rgba(255, 255, 255, 0.8)',
    lightOutlineSubtle: '0 0 0 1px rgba(255, 255, 255, 0.8)',
    darkOutline: '0 0 0 3px rgba(0, 0, 0, 0.8)',
    darkOutlineSubtle: '0 0 0 1px rgba(0, 0, 0, 0.8)'
  }
}