import { useAuth0 } from '@auth0/auth0-react'
import React, { useEffect, useContext, useState } from 'react'

export const IntercomContext = React.createContext({})

export const useIntercom = () => useContext(IntercomContext)

export const Intercom = ({ children }) => {
  const { isAuthenticated, user } = useAuth0()
  const { isIntercomLoaded, bootIntercom } = useIntercom()

  if (!isAuthenticated) {
    isIntercomLoaded && bootIntercom()
  } else {
    isIntercomLoaded && bootIntercom(user)
  }
  return <>{children}</>
}

export const IntercomProvider = ({ children, APP_ID = 'r4w7ucld' }) => {
  const [ isIntercomLoaded, setIsIntercomLoaded ] = useState(false)

  useEffect(() => {
    const loadIntercom = async () => {
      const w = window
      const ic = w.Intercom
      if (typeof ic === 'function') {
        ic('reattach_activator')
        ic('update', w.intercomSettings)
      } else {
        const d = document
        const i = function () {
          i.c(arguments)
        }
        i.q = []
        i.c = function (args) {
          i.q.push(args)
        }
        w.Intercom = i
        const l = function () {
          const s = d.createElement('script')
          s.type = 'text/javascript'
          s.async = true
          s.src = 'https://widget.intercom.io/widget/' + APP_ID
          const x = d.getElementsByTagName('script')[0]
          x.parentNode.insertBefore(s, x)
        }
        if (document.readyState === 'complete') {
          l()
        } else if (w.attachEvent) {
          w.attachEvent(
            'onload',
            l
          )
        } else {
          w.addEventListener('load', l, false)
        }
      }
      setIsIntercomLoaded(true)
    }
    loadIntercom()

  }, [ APP_ID ])

  // Initializes Intercom
  const bootIntercom = (options = {}) => {
    window &&
    window.Intercom &&
    window.Intercom('boot', { app_id: APP_ID, ...options })
  }

  // This method just calls Intercom('update'), which should be run on every
  // page change. This does two things:
  // 1. Send an update to Intercom to create an impression on the current URL
  // 2. Fetch any messages that should be delivered based on the URL and user
  const updateIntercom = () => {
    window && window.Intercom && window.Intercom('update')
  }

  // Clears user session and unloads messages
  const shutdownIntercom = () => {
    window && window.Intercom && window.Intercom('shutdown')
  }

  return (
    <IntercomContext.Provider
      value={{
        isIntercomLoaded,
        setIsIntercomLoaded,
        bootIntercom,
        updateIntercom,
        shutdownIntercom
      }}
    >
      {children}
    </IntercomContext.Provider>
  )
}
