import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { useAuth0 } from '@auth0/auth0-react'
import Big from 'big.js'
import React, { useEffect, useState } from 'react'

import { GOAL_ORDER_SPACING } from './constants'
import { LoadingIndicator } from './loading-indicator'
import { GET_BOARD } from './queries'
import { useLogout } from './use-logout'

export const createLink = (
  { code, isAuthenticated, getAccessTokenSilently, logout }
) => {
  const httpLink = createHttpLink({
    uri: 'https://hasura.gethashbrown.com/v1/graphql'
  })

  const errorLink = onError(
    ({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ extensions }) => {
          console.log(`[GraphQL Error] ${extensions.code}`)
          if (
            extensions.code === 'invalid-jwt'
            || extensions.code === 'invalid-headers'
          ) {
            logout()
          }
        })
      }

      if (networkError) {
        console.log(`[Network Error] ${networkError}`)
        if (networkError.statusCode === 401) {
          logout()
        }
      }
    }
  )

  const authenticationLink = setContext(
    async (_, { headers }) => {
      const token = isAuthenticated ? await getAccessTokenSilently() : null
      const authenticationHeaders = {}
      if (code) {
        authenticationHeaders['X-Hasura-Share-Id'] = code
      } else if (token) {
        authenticationHeaders.authorization = `Bearer ${token}`
      }

      return {
        headers: {
          ...headers,
          ...authenticationHeaders
        }
      }
    }
  )

  return from([ authenticationLink, errorLink, httpLink ])
}

export const createCache = () => {
  return new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          goals_by_pk(_, { args, toReference }) {
            return toReference({ __typename: 'goals', id: args.id })
          },
          next_goal_order: (_, { args: { board_id: boardId }, cache }) => {
            const goals = cache.readQuery({
              query: GET_BOARD, variables: { id: boardId }
            }).boards_by_pk.goals

            let nextOrder = new Big(0)
            for (const goal of goals) {
              const goalOrder = new Big(goal.order)
              if (goalOrder.gt(nextOrder)) {
                nextOrder = goalOrder
              }
            }
            return nextOrder.plus(GOAL_ORDER_SPACING).toString()
          }

        }
      }
    }
  })
}

export const Apollo = ({ code, children }) => {
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0()
  const logout = useLogout()
  const [ client, setClient ] = useState(null)

  useEffect(() => {
    if (!isLoading) {
      console.log('[Apollo] Creating client.')

      const apolloClient = new ApolloClient({
        link: createLink({
          code,
          isAuthenticated,
          getAccessTokenSilently,
          logout
        }),
        cache: createCache()
      })

      setClient(apolloClient)
    }
  }, [ code, getAccessTokenSilently, isAuthenticated, isLoading, logout ])

  if (isLoading || !client) {
    return <LoadingIndicator size='fullscreen'/>
  }

  return (
    <ApolloProvider client={client}>
      {children}
    </ApolloProvider>
  )
}