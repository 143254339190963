import { Box, ChakraProvider } from '@chakra-ui/react'
import React, { Suspense } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation
} from 'react-router-dom'

import { AbilityProvider } from './abilities'
import { ActiveUser } from './active-user'
import { Apollo } from './apollo'
import { AuthProvider } from './auth'
import { Intercom, IntercomProvider } from './intercom'
import { LoadingIndicator } from './loading-indicator'
import { ProtectedRoute } from './protected-route'
import { defaultTheme } from './theme'

const LandingPage = React.lazy(
  () => import(/* webpackChunkName: 'landing-page' */ './landing-page')
)
const AppPage = React.lazy(
  () => import(/* webpackChunkName: 'app-page' */ './app-page')
)
const SharePage = React.lazy(
  () => import(/* webpackChunkName: 'share-page' */ './share-page')
)
const CollaboratorCodePage = React.lazy(
  () => import(/* webpackChunkName: 'collaborator-code-page' */ './collaborator-code-page')
)

const InterludePage = React.lazy(
  () => import(/* webpackChunkName: 'interlude-page' */ './interlude-page')
)
const NotFoundPage = React.lazy(
  () => import(/* webpackChunkName: 'not-found-page' */ './not-found-page')
)
const PrivacyPolicyPage = React.lazy(
  () => import(/* webpackChunkName: 'privacy-page' */ './privacy-policy-page')
)
const SecurityPage = React.lazy(
  () => import(/* webpackChunkName: 'security-page' */ './security-page')
)
const TermsPage = React.lazy(
  () => import(/* webpackChunkName: 'terms-page' */ './terms-page')
)

function Pages() {
  const location = useLocation()
  let fallback = <Box bg='background.tertiary' w='100vw' height='100vh'/>
  if (location.pathname !== '/') {
    fallback = <LoadingIndicator bg='background.primary' size='fullscreen'/>
  }

  return <Suspense fallback={fallback}>
    <Switch>
      <ProtectedRoute exact path='/app' component={AppPage}/>
      <ProtectedRoute
        exact path='/collaborator-code' component={CollaboratorCodePage}
      />
      <Route exact path='/share/:code' component={SharePage}/>
      <Route exact path='/interlude' component={InterludePage}/>
      <Route exact path='/privacy' component={PrivacyPolicyPage}/>
      <Route exact path='/security' component={SecurityPage}/>
      <Route exact path='/terms' component={TermsPage}/>
      <Route exact path='/' component={LandingPage}/>
      <Route path='*' component={NotFoundPage}/>
    </Switch>
  </Suspense>
}

export const Site = () => {
  return (
    <Router>
      <AuthProvider>
        <AbilityProvider>
          <ChakraProvider theme={defaultTheme}>
            <IntercomProvider>
              <Intercom>
                <Apollo>
                  <ActiveUser>
                    <Pages/>
                  </ActiveUser>
                </Apollo>
              </Intercom>
            </IntercomProvider>
          </ChakraProvider>
        </AbilityProvider>
      </AuthProvider>
    </Router>
  )
}