import { useAuth0 } from '@auth0/auth0-react'
import React, { useEffect } from 'react'

import { LoadingIndicator } from './loading-indicator'
import { activeUserVar } from './local-state'

export const ActiveUser = ({ children }) => {
  const { user, isLoading } = useAuth0()

  useEffect(() => {
    const userInfo = {
      id: user?.sub || null,
      name: user?.name || null,
      avatar: user?.picture || null
    }
    activeUserVar(userInfo)
  }, [ user ])

  if (isLoading) {
    return <LoadingIndicator size='fullscreen'/>
  }

  return <>{children}</>
}