import { useAuth0 } from '@auth0/auth0-react'
import { useCallback } from 'react'

import { useIntercom } from './intercom'

export const useLogout = () => {
  const { logout } = useAuth0()
  const { shutdownIntercom } = useIntercom()

  return useCallback(
    () => {
      shutdownIntercom()
      logout({ returnTo: window.location.origin })
    },
    [ logout, shutdownIntercom ]
  )
}