import { gql } from '@apollo/client'

export const BOARD_CORE_FIELDS = gql`
  fragment boardCoreFields on boards {
    id
    name
  }
`

export const GOAL_CORE_FIELDS = gql`
  fragment goalCoreFields on goals {
    id
    board_id
    name
    effort
    color
    order
    allocations_aggregate {
      aggregate {
        count
      }
    }
  }
`

export const GOAL_EDIT_FIELDS = gql`
  fragment goalEditFields on goals {
    id
    name
    effort
    color
  }
`

export const RESOURCE_CORE_FIELDS = gql`
  fragment resourceCoreFields on resources {
    id
    board_id
    name
    color
    avatar
  }
`

export const RESOURCE_EDIT_FIELDS = gql`
  fragment resourceEditFields on resources {
    id
    name
    color
    avatar
  }
`

export const PERIOD_CORE_FIELDS = gql`
  fragment periodCoreFields on periods {
    id
    board_id
    name
  }
`

export const PERIOD_EDIT_FIELDS = gql`
  fragment periodEditFields on periods {
    id
    name
  }
`

export const ALLOCATION_CORE_FIELDS = gql`
  fragment allocationCoreFields on allocations {
    id
    board_id
    period_id
    resource_id
    goal_id
  }
`

export const ACCESS_CORE_FIELDS = gql`
  fragment accessCoreFields on access {
    id
    board_id
    role
    user {
      id
      name
      avatar
    }
  }
`

export const GET_BOARDS = gql`
  query GetBoards {
    boards(order_by: [{created_at: asc}]) {
      ...boardCoreFields
    }
  },
  ${BOARD_CORE_FIELDS}
`

export const GET_BOARD = gql`
  query GetBoard($id: uuid!) {
    boards_by_pk(id: $id) {
      id
      name
      resources(order_by: [{created_at: asc}]) {
        ...resourceCoreFields
      }
      periods(order_by: [{created_at: asc}]) {
        ...periodCoreFields
      }
      goals(order_by: [{order: asc}]) {
        ...goalCoreFields
      }
      allocations {
        ...allocationCoreFields
      }
    }
  }
  ${RESOURCE_CORE_FIELDS}
  ${PERIOD_CORE_FIELDS}
  ${GOAL_CORE_FIELDS}
  ${ALLOCATION_CORE_FIELDS}
`

export const ADD_BOARD = gql`
  mutation AddBoard($object: boards_insert_input!) {
    insert_boards_one(object: $object) {
      ...boardCoreFields
      access {
        ...accessCoreFields
      }
    }
  }
  ${BOARD_CORE_FIELDS}
  ${ACCESS_CORE_FIELDS}
`

export const EDIT_BOARD = gql`
  mutation EditBoard($id: uuid!, $changes: boards_set_input!) {
    update_boards_by_pk(pk_columns: {id: $id}, _set: $changes) {
      ...boardCoreFields
    }
  }
  ${BOARD_CORE_FIELDS}
`

export const REMOVE_BOARD = gql`
  mutation RemoveBoard($id: uuid!) {
    delete_boards_by_pk(id: $id) {
      id
    }
  }
`

export const GET_GOAL_BY_ID = gql`
  query GetGoalById($id: uuid!) {
    goals_by_pk(id: $id) {
      ...goalCoreFields
    }
  }
  ${GOAL_CORE_FIELDS}
`

export const ADD_GOAL = gql`
  mutation AddGoal($object: goals_insert_input!) {
    insert_goals_one(object: $object) {
      ...goalCoreFields
    }
  }
  ${GOAL_CORE_FIELDS}
`

export const EDIT_GOAL = gql`
  mutation EditGoal($id: uuid!, $changes: goals_set_input!) {
    update_goals_by_pk(pk_columns: {id: $id}, _set: $changes) {
      ...goalEditFields
    }
  }
  ${GOAL_EDIT_FIELDS}
`

export const REORDER_GOAL = gql`
  mutation ReorderGoal($id: uuid!, $order: numeric!) {
    update_goals_by_pk(pk_columns: {id: $id}, _set: {order: $order}) {
      id
      order
    }
  }
`

export const GET_NEXT_GOAL_ORDER = gql`
  query GetNextGoalOrder($boardId: uuid!) {
    next_goal_order(board_id: $boardId) @client(always: true)
  }
`

export const REMOVE_GOAL = gql`
  mutation RemoveGoal($id: uuid!) {
    delete_goals_by_pk(id: $id) {
      id
      board_id
    }
  }
`

export const ADD_RESOURCE = gql`
  mutation AddResource($object: resources_insert_input!) {
    insert_resources_one(object: $object) {
      ...resourceCoreFields
    }
  }
  ${RESOURCE_CORE_FIELDS}
`

export const EDIT_RESOURCE = gql`
  mutation EditResource($id: uuid!, $changes: resources_set_input!) {
    update_resources_by_pk(pk_columns: {id: $id}, _set: $changes) {
      ...resourceEditFields
    }
  }
  ${RESOURCE_EDIT_FIELDS}
`

export const REMOVE_RESOURCE = gql`
  mutation RemoveResource($id: uuid!) {
    delete_resources_by_pk(id: $id) {
      id
      board_id
    }
  }
`

export const ADD_PERIOD = gql`
  mutation AddPeriod($object: periods_insert_input!) {
    insert_periods_one(object: $object) {
      ...periodCoreFields
    }
  }
  ${PERIOD_CORE_FIELDS}
`

export const EDIT_PERIOD = gql`
  mutation EditPeriod($id: uuid!, $changes: periods_set_input!) {
    update_periods_by_pk(pk_columns: {id: $id}, _set: $changes) {
      ...periodEditFields
    }
  }
  ${PERIOD_EDIT_FIELDS}
`

export const REMOVE_PERIOD = gql`
  mutation RemovePeriod($id: uuid!) {
    delete_periods_by_pk(id: $id) {
      id
      board_id
    }
  }
`

export const ADD_ALLOCATION = gql`
  mutation AddAllocation($object: allocations_insert_input!) {
    insert_allocations_one(object: $object) {
      ...allocationCoreFields
    }
  }
  ${ALLOCATION_CORE_FIELDS}
`

export const REALLOCATE_ALLOCATION = gql`
  mutation ReallocateAllocation(
    $id: uuid!, $object: allocations_insert_input!
  ) {
    delete_allocations_by_pk(id: $id) {
      id
      board_id
      goal_id
    }
    insert_allocations_one(object: $object) {
      ...allocationCoreFields
    }
  }
  ${ALLOCATION_CORE_FIELDS}
`

export const REMOVE_ALLOCATION = gql`
  mutation RemoveAllocation($id: uuid!) {
    delete_allocations_by_pk(id: $id) {
      id
      board_id
      goal_id
    }
  }
`

export const GET_USER_ACCESS = gql`
  query GetUserAccess($id: String!) {
    access(where: {user_id: {_eq: $id}}, order_by: {created_at: asc, id: asc}) {
      ...accessCoreFields
    }
  }
  ${ACCESS_CORE_FIELDS}
`

export const GET_BOARD_ACCESS = gql`
  query GetBoardAccess($id: uuid!) {
    access(where: {board_id: {_eq: $id}}, order_by: {created_at: asc}) {
      ...accessCoreFields
    }
  }
  ${ACCESS_CORE_FIELDS}
`

export const ADD_BOARD_ACCESS = gql`
  mutation AddBoardAccess($object: access_insert_input!) {
    insert_access_one(
      object: $object,
      on_conflict: {
        constraint: access_board_id_user_id_key, update_columns: [role]
      }
    ) {
      ...accessCoreFields
    }
  }
  ${ACCESS_CORE_FIELDS}
`

export const EDIT_BOARD_ACCESS = gql`
  mutation EditBoardAccess($id: uuid!, $changes: access_set_input!) {
    update_access_by_pk(pk_columns: {id: $id}, _set: $changes) {
      ...accessCoreFields
    }
  }
  ${ACCESS_CORE_FIELDS}
`

export const REMOVE_BOARD_ACCESS = gql`
  mutation RemoveBoardAccess($id: uuid!) {
    delete_access_by_pk(id: $id) {
      id
      board_id
    }
  }
`