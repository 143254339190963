import ReactDOM from 'react-dom'

const render = () => {
  ReactDOM.render(require('./site').Site(), document.getElementById('root'))
}

render()

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./site', render)
}
